// Imports => React
import React, { useEffect, useState, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import {
	KEYS,
	PERMISSIONS,
	THEMES,
	TITLES,
	TYPES,
	VARIANTS,
	VISUALS,
} from '@constants';

// Imports => Utilities
import { AcIsUndefined, AcIsSet } from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcSelectBox from '@atoms/ac-select-box/ac-select-box.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
	MAIN: 'ac-edit-hydrohammer-modal',
	CONTENT: 'ac-edit-hydrohammer-modal__content',
};

const AcEditHydrohammerModal = ({
	store: { ui, hydrohammers, hydrohammer_types, companies },
	data,
	submit,
	callback,
}) => {
	const { can, cannot } = usePermissions();

	let raw_fields = {
		object_no: data.object_no,
		company_id: data.company_id,
		equipment_type_id: data.equipment_type && data.equipment_type.id,
	};
	let raw_errors = {
		object_no: null,
		company_id: null,
		equipment_type_id:
			AcIsSet(data.equipment_type) && AcIsSet(data.equipment_type.id)
				? null
				: undefined,
	};

	if (cannot(PERMISSIONS.EQUIPMENT.MANAGE_DETAILS)) {
		delete raw_errors.object_no;

		delete raw_fields.company_id;
		delete raw_errors.company_id;

		delete raw_fields.equipment_type_id;
		delete raw_errors.equipment_type_id;
	}

	const [fields, setFields] = useState(raw_fields);
	const [errors, setErrors] = useState(raw_errors);

	const { hasErrors, handleInputChange, handleInputValidation } =
		useFormActions({
			fields,
			setFields,
			errors,
			setErrors,
		});

	useEffect(() => {
		if (can(PERMISSIONS.EQUIPMENT.MANAGE_DETAILS)) {
			hydrohammer_types.list({ options: true });
			companies.list({ per_page: 999, options: true });
		}
	}, []);

	const handleTransferConfirm = () => {
		ui.confirm({
			instance: hydrohammers,
			title: TITLES.TRANSFER_HAMMER,
			content: `<p>You are about to change the ownership of this hydrohammer.</p><p>If you transfer this hydrohammer, the previous owner will no longer be able to view and manage this hydrohammer.</p><p class="h-margin-top-15"><strong>Are you sure you want to proceed?</strong></p>`,
			confirm: {
				label: 'Yes, save changes',
				callback: () =>
					submit(data.id, fields).then(() =>
						ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false)
					),
			},
		});
	};

	const handleCancel = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
	};

	const handleSubmit = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.persist) event.persist();

		if (submit)
			if (
				!AcIsUndefined(fields.company_id) &&
				fields.company_id !== data.company_id
			) {
				handleTransferConfirm();
			} else {
				submit(data.id, fields).then(() =>
					ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false)
				);
			}
	};
	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	const getObjectNumberInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Object number',
			name: 'object_no',
			value: fields.object_no,
			callback: handleInputChange,
			validation: handleInputValidation,
			disabled: cannot(PERMISSIONS.EQUIPMENT.MANAGE_DETAILS),
			focus: true,
		};
	}, [fields, fields.object_no]);

	const getEquipmentTypeSelectOptions = useMemo(() => {
		const { current_hydrohammer_types_list } = hydrohammer_types;
		let options = [];

		if (current_hydrohammer_types_list) {
			const len = current_hydrohammer_types_list.length;
			let n = 0;

			for (n; n < len; n++) {
				const item = current_hydrohammer_types_list[n];

				const object = {
					name: item.name,
					value: item.id,
				};

				options.push(object);
			}
		}

		return {
			type: TYPES.TEXT,
			label: 'Object type',
			name: 'equipment_type_id',
			placeholder: 'Select a Hydrohammer type',
			value: fields.equipment_type_id,
			callback: handleInputChange,
			validation: handleInputValidation,
			options,
			disabled: true,
		};
	}, [
		hydrohammer_types.current_hydrohammer_types_list,
		fields,
		fields.equipment_type_id,
	]);

	const getCompanySelectOptions = useMemo(() => {
		const { current_companies_list } = companies;
		let options = [
			{
				name: '- Unassigned',
				value: null,
			},
		];

		if (current_companies_list) {
			const len = current_companies_list.length;
			let n = 0;

			for (n; n < len; n++) {
				const item = current_companies_list[n];

				const object = {
					name: item.name,
					value: item.id,
				};

				options.push(object);
			}
		}

		return {
			type: TYPES.TEXT,
			label: 'Company',
			name: 'company_id',
			placeholder: 'Select a company',
			value: fields.company_id,
			callback: handleInputChange,
			validation: handleInputValidation,
			maxOptions: 6,
			options,
			disabled: cannot(PERMISSIONS.EQUIPMENT.MANAGE_DETAILS),
		};
	}, [companies.current_companies_list, fields, fields.company_id]);

	const getCancelButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.OMEGA,
			variant: VARIANTS.TEXT,
			title: 'Cancel',
			callback: handleCancel,
		};
	});

	const getSubmitButtonOptions = useMemo(() => {
		return {
			type: TYPES.SUBMIT,
			theme: THEMES.ALPHA,
			disabled: hasErrors,
			title: 'Save changes',
			callback: handleSubmit,
		};
	}, [fields, errors, hasErrors]);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<form method={'post'} onSubmit={handleSubmit}>
					<AcContainer fluid>
						<AcRow>
							<AcColumn>
								<AcTextInput {...getObjectNumberInputOptions} />
							</AcColumn>
						</AcRow>

						<AcCheckPermissions allowed={PERMISSIONS.EQUIPMENT.MANAGE_DETAILS}>
							<AcRow>
								<AcColumn>
									<AcSelectBox {...getEquipmentTypeSelectOptions} />
								</AcColumn>
							</AcRow>
						</AcCheckPermissions>

						<AcCheckPermissions allowed={PERMISSIONS.EQUIPMENT.MANAGE_DETAILS}>
							<AcRow>
								<AcColumn>
									<AcSelectBox {...getCompanySelectOptions} />
								</AcColumn>
							</AcRow>
						</AcCheckPermissions>

						<AcRow className={'h-margin-top-20'}>
							<AcColumn
								xxs={12}
								xs={7}
								sm={6}
								className={'h-text--align-left h-flex-v-align-center'}
							>
								<AcButton {...getCancelButtonOptions}>
									<span>Cancel</span>
								</AcButton>
							</AcColumn>

							<AcColumn
								xxs={12}
								xs={5}
								sm={6}
								className={'h-text--align-right'}
							>
								<AcButton {...getSubmitButtonOptions}>
									<span>Save changes</span>
								</AcButton>
							</AcColumn>
						</AcRow>
					</AcContainer>
				</form>
			</div>

			{hydrohammers.is_busy && <AcLoader loading={true} cover />}
		</div>
	);
};

export default withStore(observer(AcEditHydrohammerModal));
