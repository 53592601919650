// Imports => React
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import {
	KEYS,
	PERMISSIONS,
	THEMES,
	TITLES,
	TYPES,
	VARIANTS,
	VISUALS,
} from '@constants';

// Imports => Utilities
import { AcUUID, AcIsSet, AcIsBoolean } from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcSelectBox from '@atoms/ac-select-box/ac-select-box.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
	MAIN: 'ac-edit-profile-modal',
	CONTENT: 'ac-edit-profile-modal__content',
};

let delay = null;

const AcEditUserCredentialsModal = ({
	store: { ui, profile },
	data,
	submit,
	callback,
}) => {
	const { can, cannot } = usePermissions();

	const getRawFields = (d) => {
		if (!AcIsSet(d)) return {};

		const collection = {
			basic_password: d.basic_password,
			jean_lutz_username: d.jean_lutz_username,
		};

		return collection;
	};

	let raw_fields = getRawFields(data);
	let raw_errors = {
		jean_lutz_username: null,
		basic_password: null,
	};

	const [fields, setFields] = useState(raw_fields);
	const [errors, setErrors] = useState(raw_errors);

	const { hasErrors, handleInputChange, handleInputValidation } =
		useFormActions({
			fields,
			setFields,
			errors,
			setErrors,
		});

	useEffect(() => {}, []);

	const hasMadeChanges = useMemo(() => {
		if (!data) return false;
		const collection = Object.assign({}, getRawFields(data));
		const output = Object.assign({}, fields);

		let key = null;
		let result = false;

		for (key in collection) {
			if (AcIsSet(output[key])) {
				const valA = collection[key];
				const valB = output[key];

				if (valA !== valB) {
					result = true;
					break;
				}
			}
		}

		return result;
	}, [data, fields]);

	const handleCredentialsChangedConfirm = () => {
		ui.confirm({
			id: AcUUID(),
			instance: profile,
			title: TITLES.CREDENTIALS_UPDATED,
			content: `<p>You have made changes to your control unit credentials.</p><p class="h-margin-y-20">These changes will only be active on the control unit after sending the configuration containing your account and these credentials to the control unit.</p><p>If you did not intend to do this, click <strong>'Cancel'</strong>.</p>`,
			confirm: {
				label: 'Understood, save changes',
				callback: () =>
					submit(fields).then(() =>
						ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false)
					),
			},
		});
	};

	const handleCancel = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
	};

	const handleSubmit = (event) => {
		if (event && event.preventDefault) event.preventDefault();

		if (submit)
			if (hasMadeChanges) {
				handleCredentialsChangedConfirm();
			} else {
				submit(fields).then(() => ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false));
			}
	};

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	const getJeanLutzUserNameInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Control unit Username',
			name: 'jean_lutz_username',
			value: fields.jean_lutz_username,
			min: 5,
			callback: handleInputChange,
			validation: (name, value, required, type, min) => {
				if (value === '99999' || value === 99999) {
					const err = errors;
					err[
						'jean_lutz_username'
					] = `The username &nbsp;<strong>99999</strong>&nbsp; is already taken and cannot be used.`;
					setErrors(err);
					return `The username &nbsp;<strong>99999</strong>&nbsp; is already taken and cannot be used.`;
				}
				return handleInputValidation(name, value, required, type, min);
			},
			disabled: cannot(PERMISSIONS.PROFILE.MANAGE_JEAN_LUTZ_CREDENTIALS),
			focus: true,
		};
	}, [fields, fields.jean_lutz_username]);

	const getBasicPasswordInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Basic password',
			name: 'basic_password',
			value: fields.basic_password,
			min: 4,
			callback: handleInputChange,
			validation: handleInputValidation,
			disabled: cannot(PERMISSIONS.PROFILE.MANAGE_JEAN_LUTZ_CREDENTIALS),
		};
	}, [fields, fields.basic_password]);

	const getCancelButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.OMEGA,
			variant: VARIANTS.TEXT,
			title: 'Cancel',
			callback: handleCancel,
		};
	});

	const getSubmitButtonOptions = useMemo(() => {
		return {
			type: TYPES.SUBMIT,
			theme: THEMES.ALPHA,
			disabled: !hasMadeChanges || hasErrors,
			title: 'Save changes',
			callback: handleSubmit,
		};
	}, [fields, errors, hasErrors, hasMadeChanges]);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				{fields && (
					<form method={'post'} onSubmit={handleSubmit}>
						<AcContainer fluid>
							<AcCheckPermissions
								allowed={[
									PERMISSIONS.PROFILE.READ_JEAN_LUTZ_CREDENTIALS,
									PERMISSIONS.PROFILE.MANAGE_JEAN_LUTZ_CREDENTIALS,
								]}
							>
								<AcRow>
									<AcColumn>
										<AcTextInput {...getJeanLutzUserNameInputOptions} />
									</AcColumn>
								</AcRow>
							</AcCheckPermissions>

							<AcCheckPermissions
								allowed={[
									PERMISSIONS.PROFILE.READ_JEAN_LUTZ_CREDENTIALS,
									PERMISSIONS.PROFILE.MANAGE_JEAN_LUTZ_CREDENTIALS,
								]}
							>
								<AcRow>
									<AcColumn>
										<AcTextInput {...getBasicPasswordInputOptions} />
									</AcColumn>
								</AcRow>
							</AcCheckPermissions>

							<AcRow className={'h-margin-top-20'}>
								<AcColumn
									xxs={12}
									xs={7}
									sm={6}
									className={'h-text--align-left h-flex-v-align-center'}
								>
									<AcButton {...getCancelButtonOptions}>
										<span>Cancel</span>
									</AcButton>
								</AcColumn>

								<AcColumn
									xxs={12}
									xs={5}
									sm={6}
									className={'h-text--align-right'}
								>
									<AcButton {...getSubmitButtonOptions}>
										<span>Save changes</span>
									</AcButton>
								</AcColumn>
							</AcRow>
						</AcContainer>
					</form>
				)}
			</div>

			{profile.is_busy && <AcLoader loading={true} cover />}
		</div>
	);
};

export default withStore(observer(AcEditUserCredentialsModal));
